// // import Script from "next/script";

// import Head from "next/head";

// const GTMScript = ({isElection=false}) => {
//   return (
//     <>
//       <Head>
//         <script
//           dangerouslySetInnerHTML={{
//             __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//                 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//                 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//                 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//                 })(window,document,'script','dataLayer','GTM-KB6DXDKD');
//                 `,
//           }}
//           async
//           id="gtm-script"
//           type="text/javascript"
//         ></script>
//         {!isElection&&<script
//           dangerouslySetInnerHTML={{
//             __html: `
//           (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//                   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//                   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//                   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//                   })(window,document,'script','dataLayer','GTM-M57NDHZ');`,
//           }}
//           async
//           id="gtm-script-central"
//           type="text/javascript"
//         ></script>}
//       </Head>
//     </>
//   );
// };

// export default GTMScript;
// -----------------------------------------------------------------------------
import { useEffect, useState } from "react";
import Head from "next/head";

const GTMScript = () => {
  const [scrolledY, setScrolledY] = useState(false);
  useEffect(()=>{
    const handleScroll = () => {
      if(!scrolledY){
        setScrolledY(true);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const loadGTM = (id, scriptId) => {
      if (!document.getElementById(scriptId)) {
        const script = document.createElement("script");
        script.defer = true;
        script.id = scriptId;
        script.innerHTML = `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${id}');
        `;
        document.head.appendChild(script);
      } else {
        console.log(`${scriptId} already loaded`);
      }
    };

    // Delay the loading of GTM scripts
    const gtm1Timeout = setTimeout(() => {
      if(scrolledY){
        loadGTM("GTM-KB6DXDKD", "gtm-script-recengine");
      }
    }, 500);  

    // const gtm2Timeout = setTimeout(()=>{
    //   loadGTM("GTM-5X2B6KF", "gtm-script");
    // }, 500); 

    // Cleanup timeouts on unmount
    return () => {
      clearTimeout(gtm1Timeout);
      // clearTimeout(gtm2Timeout);
      };
  }, [scrolledY]);

  return <Head>{/* Optional meta tags or preload resources */}</Head>;
};

export default GTMScript