import dynamic from "next/dynamic";
// import Head from "next/head";
import { useContext, useEffect, useMemo, useState } from "react";
import {
  stateLangMapper,
  stateLinkMapper,
  // defaultAuthorMap,
} from "@/utils/langCodeMapper";
//import Link from "next/link";
import SVG from "react-inlinesvg";

const ShortsCarousel = dynamic(() =>
  import("@/component/Shorts/ShortsCarousel")
);
const BreakingNewsSlider = dynamic(() =>
  import("@/component/BreakingNewsSlider")
);
const HtmlWidget = dynamic(() => import("@/component/HtmlWidget"));
const BreadcrumbSchema = dynamic(() =>
  import("@/component/SeoTags/BreadcrumbSchema")
);
import defaultMetaTags from "@/constants/defaultMetaTags";
const WebpageSchema = dynamic(() =>
  import("@/component/SeoTags/WebpageSchema")
);
const ItemsListSchema = dynamic(() => import("@/component/SeoTags/ItemsList"));
const HeadTag = dynamic(() => import("@/component/SeoTags/HeadTag"));
// const RegionalNewsBox = dynamic(() => import("@/component/Regional/RegionalNewsBox"));
const StateData = dynamic(() => import("@/component/Regional/StateData"));
const NewsGrid1 = dynamic(() => import("@/component/NewsGrid/NewsGrid1"));
const NewsGrid2 = dynamic(() => import("@/component/NewsGrid/NewsGrid2"));
const AdSlot300x250 = dynamic(() => import("@/component/Ads/AdSlot300x250"));
const AdSlot728x90 = dynamic(() => import("@/component/Ads/AdSlot728x90"));
// const VDOAdsScript = dynamic(() => import("@/component/Ads/VDOAdsScript"));
// const VDOAdSlot = dynamic(() => import("@/component/Ads/VDOAdSlot"));
import { useWindowSize } from "@/hooks/useIsMobile";
import GTMScript from "@/component/Ads/GTMScript";
// const Sponsor = dynamic(() => import("@/component/Sponsor"));
// import NavigationBar from "@/component/Navigation";
// const ExitPoll = dynamic(() => import("@/component/ExitPoll"));
// Optionally, you can create a loading component
const Loading = () => <div>Loading...</div>;

// const ResultTally = dynamic(() => import("@/component/Result"), {
//   loading: () => <Loading />,
// });
// const StateExitPoll = dynamic(() => import("@/component/StateExitPoll"), {
//   loading: () => <Loading />,
// });
// const StateResultTally = dynamic(() => import("@/component/StateResultTally"), {
//   loading: () => <Loading />,
// });
const ElectionLive = dynamic(() => import("@/component/ElectionLive"), {
  loading: () => <Loading />,
});
import stateKeys from "@/constants/stateKeys";
import {
  apstagBidCallback,
  createApsSlotsHomeDesktop,
  createApsSlotsHomeMobile,
  requestBiddingFromPWT,
} from "@/utils/ads";
import { MasterConfigContext } from "@/context/MasterConfigContext";
// import { useRouter } from "next/router";
const BoardsHomeWidget = dynamic(() =>
  import("@/component/BoardResults/BoardsHomeWidget")
);
const ConstituencyLinks = dynamic(() =>
  import("@/component/constituency/ConstituencyLinks")
);
const ResultWidget = dynamic(() =>
  import("@/component/NikayChunav/ResultWidget")
);
// import ExitPollRegionalComponentV2 from "@/component/ExitPollRegionalV2";
const ExitPollRegionalComponentV2 = dynamic(() =>
  import("@/component/ExitPollRegionalV2")
);
// import KeyCandidate from "@/component/KeyCandidate";

const Home = ({
  breakingNewsData = null,
  homepageData = null,
  shortVideoData = null,
  liveVideoData = null,
  isMobileDevice = false,
  itemListData = null,
  statesNewsListData = null,
  electionLiveData = null,
  liveBlogData = null,
  constituencyList = null,
}) => {
  // console.log("statesNewsListData", statesNewsListData);
  const masterConfig = useContext(MasterConfigContext);
  const electionKeys = masterConfig["delhi_election"];
  const hrElectionKeys = masterConfig["haryana_election"];
  const boardsKeys = masterConfig["boards_result"];
  const electionPassedCategory = "delhi-assembly";
  // console.log("Homepage data ",homepageData);
  const size = useWindowSize();
  // const [activeState , setActiveState] = useState("MP");
  // const availableStates = ["MP" , "RJ" , "HR" , "BR"]
  const keyBindings = {
    Category: ["NewsTak-homepage"],
    Keywords: homepageData?.seo_detail?.meta_keyword.split(","),
  };

  const stateMapKeys = useMemo(() => {
    if (statesNewsListData && Object.keys(statesNewsListData)) {
      const keys = Object.keys(statesNewsListData).filter(
        (key) => key !== "cache"
      );
      return keys;
    }
    return [];
  }, [statesNewsListData]);
  const [isScroll, setIsScroll] = useState(false);
  useEffect(() => {
    function handleScroll() {
      if (!isScroll) {
        setIsScroll(true);
      }
    }
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isScroll]);

  useEffect(() => {
    setTimeout(() => {
      window.googletag = window.googletag || { cmd: [] };
      if (window.screen.width < 900) {
        googletag.cmd.push(function () {
          //console.log("hp slots is isMobileDevice true");

          if (!isScroll) {
            googletag.destroySlots();
            googletag
              .defineSlot(
                "/1007232/News_tak_Mobile_HP_ATF_300x250",
                [300, 250],
                "div-gpt-ad-1659434453897-0"
              )
              .addService(googletag.pubads());
          }
          if (isScroll) {
            googletag
              .defineSlot(
                "/1007232/News_tak_Mobile_HP_MTF-1_300x250",
                [300, 250],
                "div-gpt-ad-1659434604998-0"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/News_tak_Mobile_HP_MTF-2_300x250",
                [300, 250],
                "div-gpt-ad-1659434785315-0"
              )
              .addService(googletag.pubads());

            {
              stateMapKeys?.length > 3 &&
                googletag
                  .defineSlot(
                    "/1007232/News_tak_Mobile_HP_MTF-3_300x250",
                    [300, 250],
                    "div-gpt-ad-1659435328239-0"
                  )
                  .addService(googletag.pubads());
            }
            googletag
              .defineSlot(
                "/1007232/News_tak_Mobile_HP_BTF_300x250",
                [300, 250],
                "div-gpt-ad-1659435474737-0"
              )
              .addService(googletag.pubads());
          }
          {
            !isScroll &&
              googletag
                .defineSlot(
                  "/1007232/News_tak_Mobile_HP_Bottom_320x50",
                  [320, 50],
                  "div-gpt-ad-1659435612077-0"
                )
                .addService(googletag.pubads());
          }

          googletag.pubads().enableSingleRequest();
          googletag.pubads().disableInitialLoad();
          googletag.enableServices();
          requestBiddingFromPWT();
          Object.keys(keyBindings).forEach((key) => {
            googletag.pubads().setTargeting(key, keyBindings[key]);
          });
          googletag?.cmd.push(function () {
            var INTERSTITIALAd = googletag
              ?.defineOutOfPageSlot(
                "/1007232/News_tak_Mobile_HP_Mweb_Interstitial",
                googletag?.enums.OutOfPageFormat.INTERSTITIAL
              )
              ?.addService(googletag.pubads());
            INTERSTITIALAd && googletag?.display(INTERSTITIALAd);
          });
        });
      } else {
        googletag.cmd.push(function () {
          if (!isScroll) {
            googletag.destroySlots();

            googletag
              .defineSlot(
                "/1007232/News_tak_Desktop_HP_ATF_728x90",
                [728, 90],
                "div-gpt-ad-1707804176041-0"
              )
              .addService(googletag.pubads());

            googletag
              .defineSlot(
                "/1007232/News_tak_Desktop_HP_ATF_300x250",
                [300, 250],
                "div-gpt-ad-1707804176041-1"
              )
              .addService(googletag.pubads());
          }
          if (isScroll) {
            googletag
              .defineSlot(
                "/1007232/News_tak_Desktop_HP_MTF_1_728x90",
                [728, 90],
                "div-gpt-ad-1707804176041-2"
              )
              .addService(googletag.pubads());

            googletag
              .defineSlot(
                "/1007232/News_tak_Desktop_HP_MTF_2_728x90",
                [728, 90],
                "div-gpt-ad-1707804176041-3"
              )
              .addService(googletag.pubads());
            googletag
              .defineSlot(
                "/1007232/News_tak_Desktop_HP_BTF_728x90",
                [728, 90],
                "div-gpt-ad-1707804176041-4"
              )
              .addService(googletag.pubads());
          }

          googletag.pubads().enableSingleRequest();
          googletag.pubads().disableInitialLoad();
          googletag.enableServices();
          requestBiddingFromPWT();
          Object.keys(keyBindings).forEach((key) => {
            googletag.pubads().setTargeting(key, keyBindings[key]);
          });
        });
      }
      if (isScroll) {
        apstag?.fetchBids(
          {
            slots:
              window.screen.width < 900
                ? createApsSlotsHomeMobile({ mtf2: false, mtf3: false })
                : createApsSlotsHomeDesktop({ mtf2: false, mtf3: false }),
            timeout: 2e3,
          },
          apstagBidCallback
        );
      }
    }, 10);
  }, [isScroll]);

  const firstActive = useMemo(() => {
    let first = false;
    let firstActiveItem = "";
    electionLiveData?.content &&
      Object?.keys(electionLiveData?.content).forEach((key) => {
        if (
          first === false &&
          electionLiveData?.content?.[key]?.status === "1"
        ) {
          firstActiveItem = key;
          first = true;
        }
      });
    return firstActiveItem;
  }, []);

  // const router = useRouter();
  // const currentPath = router?.asPath;
  // const menuList = [
  //   ...(electionKeys?.newExitPoll
  //     ? [
  //         {
  //           title: "एक्जिट पोल",
  //           src: "/assets/icons/svg/archive.svg",
  //           slug:
  //             `election/${electionPassedCategory}/exit-poll-result` ||
  //             `election/${electionPassedCategory}/exit-poll-result-2024`,
  //         },
  //       ]
  //     : []),

  //   ...(electionKeys?.showConstituencyTab
  //     ? [
  //         {
  //           title: "हर सीट", //"निर्वाचन क्षेत्र",
  //           src: "/assets/i4.gif", //"/assets/icons/svg/chunavSeat.svg",
  //           slug: `election/${electionPassedCategory}/constituency-list`,
  //         },
  //       ]
  //     : []),

  //   ...(electionKeys?.showKeyCand
  //     ? [
  //         {
  //           title: "VIP सीट", // "प्रमुख उम्मीदवार",
  //           src: "/assets/icons/svg/keycandidate.svg",
  //           slug:
  //             `election/${electionPassedCategory}/key-candidates-list` ||
  //             `election/${electionPassedCategory}/key-candidates-list-2024`,
  //         },
  //       ]
  //     : []),
  //   {
  //     title: "खबरें",
  //     src: "/assets/icons/svg/khabarSym.svg",
  //     slug: `election/${electionPassedCategory}`,
  //   },
  //   ...(electionKeys?.showLiveUpdate
  //     ? [
  //         {
  //           title: "लाइव अपडेट्स", // "प्रमुख उम्मीदवार",
  //           src: "/assets/liveIcon.gif",
  //           slug: `election/${electionPassedCategory}/constituency-list#election-auto-live-blogs`,
  //         },
  //       ]
  //     : []),

  //   ...(electionKeys?.showResult
  //     ? [
  //         {
  //           title: "रिजल्ट", // "चुनाव परिणाम",
  //           src: "/assets/icons/svg/cup.svg",
  //           slug: `election/${electionPassedCategory}/results`,
  //         },
  //       ]
  //     : []),

  //   // {
  //   //   title: "शेड्यूल",
  //   //   src: "/assets/icons/svg/calender.svg",
  //   //   slug: `election/${electionPassedCategory}/schedule`,
  //   // },

  //   // ...(electionKeys?.showFaqs
  //   //   ? [
  //   //       {
  //   //         title: `${stateHindi} विधानसभा चुनाव से जुड़े सवाल जवाब`,
  //   //         src: "/assets/icons/svg/faqSymbol.svg",
  //   //         slug: `${electionPassedCategory}/faqs`,
  //   //       },
  //   //     ]
  //   //   : []),
  // ];

  return (
    <section className="mx-4">
      <h1 style={{ display: "none" }}>NewsTak</h1>
      <HeadTag
        title={defaultMetaTags.title}
        description={defaultMetaTags.description}
        keywords={defaultMetaTags.keywords}
        image={
          process.env.NEXT_PUBLIC_WEBSITE_URL + `/assets/brand-logo-1-ns.png`
        }
        canonicalUrl="https://www.newstak.in"
        lcpImage={
          homepageData?.left?.[0] &&
          homepageData?.left[0]?.[0] &&
          homepageData?.left[0][0]?.content?.[0]
            ? homepageData?.left[0][0]?.content?.[0]?.image_small +
              "?size=360:203"
            : ""
        }
      />
      <GTMScript />
      <WebpageSchema
        title={defaultMetaTags.title}
        description={defaultMetaTags.description}
        keywords={defaultMetaTags.keywords}
        canonicalUrl={
          process.env.NEXT_PUBLIC_WEBSITE_URL +
          homepageData?.seo_detail?.canonical_url
        }
      />
      <BreadcrumbSchema />
      <ItemsListSchema data={itemListData?.content} />
      {/* <VDOAdsScript /> */}

      {breakingNewsData && (
        <section className="my-4">
          <BreakingNewsSlider breakingNewsData={breakingNewsData} />
        </section>
      )}
      {/* {size.width !== undefined &&
        (size.width < 900 ? (
          <></>
        ) : (
          <AdSlot728x90
            divId="div-gpt-ad-1707804176041-0"
            minWidth="730px"
            minHeight="90px"
          />
        ))} */}

      {/* <Sponsor color="#EE3436" name="चुनाव" /> */}
      {/* <NavigationBar /> */}
      <AdSlot728x90
        divId="div-gpt-ad-1707804176041-0"
        minWidth="730px"
        minHeight="90px"
      />
      {/* <div className="flex gap-4 py-1 items-center  flex-row overflow-x-scroll scrollbar-hide mt-2">
        {menuList?.map((item, index) => (
          <>
            <>
              {index == 0 && (
                <h2 className="font-semibold text-xl">दिल्ली चुनाव:</h2>
              )}
            </>
            <a
              className={`${
                currentPath === `/${menuList[index]?.slug}` ||
                (currentPath.includes("/constituencies") &&
                  menuList[index]?.slug ===
                    `election/${electionPassedCategory}/constituency-list`)
                  ? "bg-primary text-white border-2 border-primary hover:text-primary"
                  : "border-2 text-gray-600 hover:text-primary"
              } px-3 py-1  rounded-xl whitespace-nowrap flex flex-row items-center gap-1 justify-center `}
              href={`${process.env.NEXT_PUBLIC_WEBSITE_URL}/${menuList[index]?.slug}`}
              key={item?.slug}
              target={"_self"}
            >
              {["/assets/liveIcon.gif", "/assets/i4.gif"]?.some((icon) =>
                item?.src?.includes(icon)
              ) ? (
                <img className="h-4 w-4" src={item?.src} />
              ) : (
                <SVG className="h-4 w-4" src={item?.src} />
              )}
              <p>{item?.title}</p>
            </a>
          </>
        ))}
      </div> */}
      {hrElectionKeys?.showNikayWidget && (
        <ResultWidget isMobileDevice={isMobileDevice} />
      )}
      {/* <ExitPoll />
      <ResultTally /> */}
      <AdSlot300x250
        divId="div-gpt-ad-1659434453897-0"
        minWidth="300px"
        minHeight="250px"
      />
      {boardsKeys?.showHomeWidget && (
        <BoardsHomeWidget
          isMobileDevice={isMobileDevice}
          tenthResult={boardsKeys?.show10result && !boardsKeys?.show12result}
          hybridResult={boardsKeys?.show10result && boardsKeys?.show12result}
        />
      )}
      {electionKeys?.newExitPoll && (
        <>
          <h2 className="text-2xl font-semibold my-1">एग्जिट पोल्स</h2>
          <ExitPollRegionalComponentV2 masterKey="delhi_election" />
        </>
      )}
      {/* <StateExitPoll masterKey="delhi_election" />

      <StateResultTally masterKey="delhi_election" /> */}
      {isMobileDevice && (
        <div className="top-layer-election" style={{ position: "sticky" }}>
          <ElectionLive
            electionLiveData={electionLiveData?.content}
            firstActive={firstActive}
          />
        </div>
      )}
      {/* <AdSlot728x90
        divId="div-gpt-ad-1707804176041-0"
        minWidth="730px"
        minHeight="90px"
      /> */}

      {homepageData?.left?.map((item, index) => {
        return (
          <div key={index}>
            {item[0]?.widget_name === "uptak_hp_featured_1" &&
              item[0]?.info?.id != stateKeys.alpha.rjTak &&
              item[0]?.info?.id != stateKeys.alpha.hrTak &&
              item[0]?.info?.id != stateKeys.alpha.biharTak &&
              item[0]?.info?.id != stateKeys.alpha.mpTak &&
              item[0]?.info?.id != stateKeys.alpha.upTak &&
              item[0]?.info?.id != stateKeys.alpha.delhiTak &&
              item[0]?.content?.length > 0 && (
                <section
                  id={index}
                  key={index}
                  className="my-10 sm:my-6 xs:my-4"
                >
                  <NewsGrid1
                    newsData={item[0]}
                    isLCP={true}
                    showLiveVideo={index === 0}
                    liveVideoData={liveVideoData}
                    isMobileDevice={size.width < 900 ? true : false}
                    showAd={isMobileDevice && index === 0 ? true : false}
                  />
                </section>
              )}

            {index == 4 && isScroll && (
              <>
                <section>
                  <AdSlot300x250
                    divId="div-gpt-ad-1659434785315-0"
                    minWidth="300px"
                    minHeight="250px"
                  />
                  <AdSlot728x90
                    divId="div-gpt-ad-1707804176041-3"
                    minWidth="728px"
                    minHeight="90px"
                  />
                  <div>
                    <p className="text-[22px]">राज्य की खबरें</p>
                    <StateData StateData={homepageData?.left} />
                  </div>
                </section>
              </>
            )}

            {/* {index == 0 && <KeyCandidate />} */}
            {item[0]?.widget_name === "uptak_hp_shorts_carousal_1" &&
              shortVideoData?.content?.length > 0 && (
                <section
                  id={index}
                  key={index + "_shorts"}
                  className="my-10 sm:my-6 xs:my-4"
                >
                  <ShortsCarousel shortVideoData={shortVideoData} />
                </section>
              )}
            {item[0]?.widget_name === "uptak_hp_category_1" &&
              item[0]?.content?.length > 0 && (
                <section id={index} key={index}>
                  <div className="my-10 sm:my-6 xs:my-4 hidden md:block lg:block">
                    <NewsGrid2
                      key={index}
                      newsData={item[0]}
                      isMobileDevice={size.width < 900 ? true : false}
                    />
                  </div>
                  <div className="my-10 sm:my-6 xs:my-4 hidden md:hidden lg:hidden sm:block mb:block xs:block md-hidden">
                    <NewsGrid1
                      key={index}
                      newsData={item[0]}
                      isMobileDevice={size.width < 900 ? true : false}
                    />
                  </div>
                </section>
              )}
            {item[0]?.widget_name === "html_widget" &&
              item[0]?.content?.length > 0 && (
                <section
                  id={index}
                  className="my-10 sm:my-6 xs:my-4"
                  key={index}
                >
                  <HtmlWidget newsData={item[0]} />
                </section>
              )}

            {/** Ads at relevant position */}
            {index === 1 && isScroll && (
              <div key={index + "_ads"} id={index}>
                <AdSlot300x250
                  divId="div-gpt-ad-1659434604998-0"
                  minWidth="300px"
                  minHeight="250px"
                />
                <AdSlot728x90
                  divId="div-gpt-ad-1707804176041-2"
                  minWidth="728px"
                  minHeight="90px"
                />
              </div>
            )}

            {index === 8 && <div key={index + "_ads"} id={index}></div>}
          </div>
        );
      })}

      {isScroll &&
        stateMapKeys?.length > 0 &&
        stateMapKeys?.map((objKey, index) => {
          if (stateLangMapper[objKey] === ("यूपी" && "मुंबई")) {
            return <></>;
          } else {
            <>
              {index === 1 && (
                <div key={index + "_ads"} id={index}>
                  {/* <AdSlot300x250
                    divId="div-gpt-ad-1659434785315-0"
                    minWidth="300px"
                    minHeight="250px"
                  />
                  <AdSlot728x90
                    divId="div-gpt-ad-1707804176041-3"
                    minWidth="728px"
                    minHeight="90px"
                  /> */}
                </div>
              )}
              {index === 2 && isScroll && (
                <div key={index + "_ads"} id={index}>
                  <AdSlot300x250
                    divId="div-gpt-ad-1659435328239-0"
                    minWidth="300px"
                    minHeight="250px"
                  />
                </div>
              )}
              <section id={"custom section"} key={objKey ?? index}>
                <div className="my-10 sm:my-6 xs:my-4 hidden md:block lg:block">
                  <NewsGrid1
                    key={objKey}
                    newsData={{
                      content: statesNewsListData[objKey]?.slice(0, 6),
                      info: {
                        name:
                          objKey in stateLangMapper
                            ? `${stateLangMapper[objKey]} की खबरें`
                            : objKey,
                      },
                    }}
                    isMobileDevice={size.width < 900 ? true : false}
                  />
                </div>
                <div className="my-10 sm:my-6 xs:my-4 hidden md:hidden lg:hidden sm:block mb:block xs:block md-hidden">
                  <NewsGrid2
                    key={objKey ?? index}
                    newsData={{
                      content: statesNewsListData[objKey]?.slice(0, 6),
                      info: {
                        name:
                          objKey in stateLangMapper
                            ? `${stateLangMapper[objKey]} की खबरें`
                            : objKey,
                      },
                    }}
                    isMobileDevice={size.width < 900 ? true : false}
                  />
                </div>
              </section>
              <a
                className="flex justify-center items-center h-10 w-full my-4 border rounded-md border-gray-300 text-gray-500 hover:text-primary hover:bg-gray-100"
                href={objKey in stateLinkMapper ? stateLinkMapper[objKey] : "#"}
                target="_blank"
              >
                <div className="flex justify-center items-center">
                  {`${
                    objKey in stateLangMapper ? stateLangMapper[objKey] : objKey
                  } की सभी खबरें`}
                  <SVG
                    className="ml-2 h-4 w-4"
                    src="/assets/icons/svg/send.svg"
                  />
                </div>
              </a>
            </>;
          }
        })}

      {electionKeys?.autoLiveBlog && (
        <section id={"345345"} key="99934534599">
          <div className="my-8">
            <h2 className="text-primary text-xl font-semibold">
              Delhi Constituencies Live Result
            </h2>
            <ConstituencyLinks
              autoLiveBlogRedirect={electionKeys?.autoLiveBlogRedirect}
              constituencyListData={constituencyList}
              customTitle={null}
              electionPassedCategory={electionPassedCategory}
            />
          </div>
        </section>
      )}
      {isScroll && (
        <AdSlot728x90
          divId="div-gpt-ad-1707804176041-4"
          minWidth="728px"
          minHeight="90px"
        />
      )}
      {isScroll && (
        <AdSlot300x250
          divId="div-gpt-ad-1659435474737-0"
          minWidth="300px"
          minHeight="250px"
        />
      )}
    </section>
  );
};

export default Home;
